<template>
  <v-container>
    <p class="text-h6 font-weight-bold">Keranjang</p>

    <v-row v-if="loading">
      <v-col>
        <div class="d-flex align-center justify-center">
          <v-progress-circular indeterminate></v-progress-circular>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="!loading" justify="center">
      <v-col>
        <v-alert v-if="cart_items.length < 1" color="warning" icon="mdi-cancel" density="compact">Tidak ada produk dalam
          keranjang</v-alert>
        <v-alert v-else-if="rejected.length > 0" color="primary-container" icon="mdi-cancel" density="compact">Yahhh,
          ada tiket yang tidak bisa diproses.
          <v-btn variant="text" class="text-none text-primary pa-0" @click="showRejected">Lihat</v-btn></v-alert>
        <v-alert color="secondary-dark" type="info" density="compact" class="text-secondary font-weight-medium">Kamu
          hanya bisa membeli dari 1 toko</v-alert>
      </v-col>
    </v-row>

    <v-row justify="center" v-if="cart_items.length > 0">
      <v-col cols="12" xxl="8" xl="8" lg="8" md="8" sm="8">
        <v-card rounded="md" class="pa-4 mb-2" v-for="store in cart_items" :key="store.store_id">
          <v-checkbox v-model="store.selected" @change="selectStore(store)" :label="store.store_name"
            :disabled="(isSingleStoreSelected && !store.selected) || firstSelectedStoreId !== null && firstSelectedStoreId !== store.store_id"></v-checkbox>
          <v-card rounded="md" class="pa-4">
            <template v-for="(product, productIndex) in store.products" :key="product">
              <v-checkbox
                :disabled="firstSelectedStoreId !== null && firstSelectedStoreId !== product.store.store_id || isProductRejected(product.product_types) || (isSingleStoreSelected && !store.selected)"
                @click="selectProduct($event, product, product.store.store_id)" :model-value="isProductSelected(product.product_types) &&
                  !isProductRejected(product.product_types)
                  " :indeterminate="!isProductSelected(product.product_types) &&
                    isIndeterminate(product.product_types)
                    " :label="product.product_name"></v-checkbox>
              <template v-for="(type, index) in product.product_types" :key="type">
                <v-sheet width="auto" class="d-flex flex-row ma-2">
                  <v-checkbox-btn
                    :disabled="type.rejected || firstSelectedStoreId !== null && firstSelectedStoreId !== product.store.store_id || (isSingleStoreSelected && !store.selected)"
                    v-model="type.selected" inline />
                  <v-card @click="
                    this.$router.push({
                      name: 'detail',
                      params: { id: product.product_id },
                    })
                    " :style="{ cursor: 'pointer' }" :id="type.producttype_id" elevation="0" class="d-flex w-100">
                    <v-img @contextmenu.prevent :src="product.product_details[0].productdetail_image_url" :lazy-src="product.product_details[0].productdetail_image_url
                      " width="200" :class="{ bnw: type.rejected }" class="flex-shrink-1 flex-grow-0" cover>
                      <div class="bg-tertiary-dark rounded-be-lg d-inline-block">
                        <p class="pa-2 text-bg-primary-dark text-body-2 font-weight-bold d-inline-block text-truncate"
                          :style="{ 'max-width': '150px' }">
                          {{ product?.store_name }}
                        </p>
                      </div>
                    </v-img>

                    <div class="ps-5 flex-grow-1">
                      <div>
                        <p class="text-body-1 font-weight-bold">
                          {{ type.producttype_name }}
                        </p>
                        <p class="text-subtitle-1 font-weight-bold text-secondary-60">
                          {{ numberToRupiah(parseInt(type.producttype_price))
                          }}<span class="text-black text-subtitle-2">/tiket</span>
                        </p>
                        <p>
                          <v-chip label color="primary">
                            <span v-if="type.is_limited">{{
                              `Sisa Tiket: ${type.producttype_qty -
                                type.producttype_booked_qty >=
                                0
                                ? type.producttype_qty -
                                type.producttype_booked_qty
                                : 0
                              }`
                            }}</span>
                            <span v-else>Tidak terbatas</span>
                          </v-chip>
                        </p>
                      </div>
                    </div>
                  </v-card>
                </v-sheet>

                <div class="d-flex justify-end align-center">
                  <v-btn @click="
                    dialog.delete = {
                      state: true,
                      cartitem_id: product.cartitem_id,
                      producttype_name: type.producttype_name,
                      product_types: product.product_types,
                      products: cart_items,
                      index: index,
                      productIndex: productIndex,
                    }
                    " color="primary" variant="text" :loading="deleteLoading" icon="mdi-trash-can">
                  </v-btn>

                  <v-chip color="primary" label v-if="type.rejected" variant="tonal">Tidak Dapat Dibeli</v-chip>
                  <v-btn v-if="!type.rejected" @click="updateQty(-1, type)" variant="elevated" color="primary"
                    icon="mdi-minus" size="x-small" class="mx-2" rounded="lg"
                    :disabled="type.qty <= type.producttype_minimum_order">
                  </v-btn>
                  <p v-if="!type.rejected" class="mx-4 font-weight-black">
                    {{ type.qty }}
                  </p>
                  <v-btn v-if="!type.rejected" @click="updateQty(1, type)" variant="elevated" color="primary"
                    icon="mdi-plus" size="x-small" class="mx-2" rounded="lg" :disabled="type.qty >= type.producttype_maximum_order ||
                      (type.is_limited && type.qty >= type.producttype_qty)
                      ">
                  </v-btn>
                </div>
                <v-divider></v-divider>
              </template>
            </template>
          </v-card>
        </v-card>
      </v-col>

      <v-col cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
        <div class="sticky">
          <v-card class="pa-2 mb-4">
            <v-card-title class="text-primary text-h6 font-weight-bold">Pesananmu</v-card-title>
            <v-row class="mx-4" no-gutters align="center" justify="center">
              <v-col cols="auto" class="me-auto">
                <p class="text-body-2 font-weight-black">
                  Total ({{ getTotalQTY }} Tiket)
                </p>
              </v-col>
              <v-col cols="auto">
                <p class="text-body-2 font-weight-black">
                  {{ numberToRupiah(getTotalPrice) }}
                </p>
              </v-col>
            </v-row>

            <v-card-actions class="mx-2">
              <div v-if="getTotalQTY > maxOrderTicket" class="rounded text-center bg-red pa-2"
                :style="{ width: '100%' }">
                Maksimal Pembelian 20 Tiket
              </div>

              <v-btn block v-else variant="elevated" @click="buy" color="primary">
                Beli
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row no-gutters align="center" justify="center">
            <p class="text-grey font-weight-medium text-center">
              Pembayaranmu aman di e-tix
            </p>
            <v-col cols="12" class="d-flex justify-center">
              <v-sheet width="100">
                <v-img @contextmenu.prevent width="100" src="@/assets/logo_duitku.png"></v-img>
              </v-sheet>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="dialog.delete.state" width="auto">
    <v-card>
      <v-card-title>Konfirmasi Hapus Produk</v-card-title>
      <v-card-text>
        Apakah anda yakin ingin menghapus produk
        <b>{{ dialog.delete.producttype_name }}</b> dari keranjang?
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" variant="tonal" @click="dialog.delete.state = false">Tutup</v-btn>

        <v-btn color="error" variant="tonal" @click="deleteFromCart()">Hapus</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-snackbar snackbar color="white" v-model="snackbar.status" rounded="pill">
    <div class="text-black">
      {{ snackbar.text }}
    </div>

    <template v-slot:actions>
      <v-btn color="pink" rounded="pill" variant="text" @click="snackbar.status = false">
        Tutup
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import Cookies from "js-cookie";
import limitation from "@/config/limitation";
import { numberToRupiah } from "@/utils/numberFormatter";
import moment from "moment";

export default {
  data() {
    return {
      deleteLoading: false,
      dialog: {
        delete: {
          cartitem_id: null,
          state: false,
          producttype_name: null,
          product_types: null,
        },
      },
      snackbar: {
        text: null,
        status: false,
      },
      loading: true,
      user: null,
      cart: [],
      cart_items: [],
      rejected: [],
      maxOrderTicket: limitation.maxOrderTicket,
      firstSelectedStoreId: null, // Track the store_id of the first selected product
    };
  },

  computed: {
    isSingleStoreSelected() {
      return this.cart_items.some((store) => store.selected);
    },
    getTotalPrice() {
      return this.cart_items.reduce((total, store) => {
        return total + store.products.reduce((subTotal, product) => {
          return subTotal + product.product_types.reduce((typeTotal, type) => {
            return typeTotal + (type.selected && !type.rejected && type.is_active ? type.producttype_price * type.qty : 0);
          }, 0);
        }, 0);
      }, 0);
    },
    getTotalQTY() {
      return this.cart_items.reduce((total, store) => {
        return total + store.products.reduce((subTotal, product) => {
          return subTotal + product.product_types.reduce((typeTotal, type) => {
            return typeTotal + (type.selected && !type.rejected && type.is_active ? type.qty : 0);
          }, 0);
        }, 0);
      }, 0);
    },
  },

  async created() {
    const cookiesUser = Cookies.get("user_data");
    if (cookiesUser) {
      this.user = JSON.parse(cookiesUser);
      this.getDataFromApi();
    }
  },

  methods: {
    numberToRupiah(number) {
      return numberToRupiah(number);
    },
    buy() {
      const cart = JSON.parse(JSON.stringify(this.cart));
      const filteredCart = cart.filter((cart) => {
        cart.product_types = cart.product_types.filter((type) => {
          return !type.rejected && type.selected;
        });
        return cart.product_types.length > 0;
      });

      const data = [];
      for (const product of filteredCart) {
        const store = data.find((e) => e.store_id === product.store_id);
        if (!store) {
          data.push({
            store_id: product.store_id,
            products: [product],
          });
        } else {
          store.products.push(product);
        }
      }

      if (data.length === 0) {
        this.snackbar.text = "Pilih item pada cart terlebih dahulu!";
        this.snackbar.status = true;
      } else {
        this.$store.state.cart = data;
        this.$router.push({ name: "purchase" });
      }
    },
    isIndeterminate(productType) {
      return productType.some((type) => type.selected);
    },
    isProductSelected(productType) {
      return productType.every((type) => type.selected || type.rejected);
    },
    isProductRejected(productType) {
      return productType.every((type) => type.rejected);
    },
    selectProduct(event, product, store_id) {
      if (this.firstSelectedStoreId === null && event.target.checked) {
        this.firstSelectedStoreId = store_id;
      }

      if (this.firstSelectedStoreId === store_id || !event.target.checked) {
        product.product_types.forEach((type) => {
          if (!type.rejected) type.selected = event.target.checked;
        });
      } else {
        this.showSnackbar("Hanya produk dari toko yang sama yang dapat dipilih.");
      }

      this.cart_items.map((store) => {
        if (store.store_id === store_id) {
          const allSelected = store.products.every((product) =>
            product.product_types.every((type) => type.selected || type.rejected)
          );
          store.selected = allSelected;
        }
      })

      const hasSelected = this.cart_items.some((store) =>
        store.products.some((product) =>
          product.product_types.some((type) => type.selected)
        )
      );
      if (!hasSelected) this.firstSelectedStoreId = null;
    },
    selectStore(store) {
      this.firstSelectedStoreId = store.selected ? store.store_id : null;
      store.products.forEach((product) => {
        product.product_types.forEach((type) => {
          if (!type.rejected) {
            type.selected = store.selected;
          }
        });
      });
    },
    goToElement(element) {
      const headerOffset = 100;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    },
    showRejected() {
      const id = this.rejected[0];
      const element = document.getElementById(id);
      this.goToElement(element);
    },
    groupByStore(cartItems) {
      return cartItems.reduce((acc, item) => {
        const store = acc.find(group => group.store_name === item.store.store_name);
        if (store) {
          store.products.push(item);
        } else {
          acc.push({
            store_name: item.store.store_name,
            store_id: item.store.store_id,
            selected: false,
            products: [item],
          });
        }
        return acc;
      }, []);
    },
    async getDataFromApi() {
      this.loading = true;
      await this.axios
        .get(`cart/view/${this.user.user_id}`)
        .then((response) => {
          const data = response.data.response.carts;
          this.cart = data;
          if (data.length) {
            const filtered_cart = data.map((cart) => {
              const publishDate = cart.product_publish_date;
              cart.is_upcoming = moment.utc(publishDate).local().isAfter(moment());

              const pulloutDate = cart.product_pull_out_date;
              cart.is_expired = moment.utc(pulloutDate).local().isBefore(moment())

              cart.product_types.map((productType) => {
                const rejected =
                  cart.is_upcoming == true ||
                  cart.is_expired == true ||
                  productType.is_active == false ||
                  productType.is_deleted == true ||
                  cart.is_deleted == true ||
                  cart.is_active == false ||
                  (productType.is_limited == true &&
                    (productType.producttype_qty -
                      productType.producttype_booked_qty <=
                      0 ||
                      productType.producttype_qty -
                      productType.producttype_booked_qty <
                      productType.qty));

                if (rejected) {
                  this.rejected.push(productType.producttype_id);
                }
                productType.rejected = rejected;
                productType.selected = false;
                return productType;
              });

              cart.product_types.sort((a, b) => {
                const name_a = a.producttype_name.toLowerCase();
                const name_b = b.producttype_name.toLowerCase();

                if (name_a < name_b) return -1;
                if (name_a > name_b) return 1;
                return 0;
              });

              return cart;
            });
            this.cart_items = this.groupByStore(filtered_cart);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async updateQty(qty, productType) {
      const producttype_id = parseInt(productType.producttype_id);
      await this.axios
        .put(`cart/edit`, {
          user_id: this.user.user_id,
          producttype_id: producttype_id,
          qty: qty,
        })
        .then((response) => {
          if (!response.data.error) {
            this.showSnackbar("Berhasil mengubah keranjang!");
            productType.qty += qty;
          }
        })
        .catch(() => {
          this.showSnackbar("Gagal mengubah keranjang!");
        });
    },
    async deleteFromCart() {
      const index = this.dialog.delete.index;
      const productIndex = this.dialog.delete.productIndex;

      this.dialog.delete.state = false;
      this.deleteLoading = true;
      await this.axios
        .delete(`cart/delete`, {
          data: {
            user_id: this.user.user_id,
            product_types: [
              this.dialog.delete.product_types[index]?.producttype_id,
            ],
          },
        })
        .then((response) => {
          if (!response.data.error) {
            this.showSnackbar("Berhasil menghapus produk dari keranjang");
            this.$store.state.countCartChange = true;
            this.dialog.delete.product_types.splice(index, 1);

            if (this.dialog.delete.product_types.length === 0) {
              this.dialog.delete.products.splice(productIndex, 1);
              this.cart_items.forEach((cartItem, index) => {
                if (this.cart_items.length == 0) {
                  cartItem.splice(index, 1);
                }
              });
            }
          }
        })
        .catch((error) => {
          console.log(error);
          this.showSnackbar("Gagal menghapus produk dari keranjang");
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    showSnackbar(text) {
      this.snackbar.text = text;
      this.snackbar.status = true;
    },
  },
};
</script>

<style>
.bnw {
  filter: grayscale(100%);
}
</style>
